import React from 'react';

import styles from './heroSection.module.css';

const HeroSection = () => {

  return (
    <div id="home" className={styles.root}>
      <h1>Bienvenue sur <span>mon</span> 
        <span> portfolio. 
          <svg className={styles.linksvgline} viewBox="0 0 400 50" xmlns="http://www.w3.org/2000/svg">
            <path id="svg_line_1" d="m 1.986,8.91 c 55.429038,4.081 111.58111,5.822 167.11781,2.867 22.70911,-1.208 45.39828,-0.601 68.126,-0.778 28.38173,-0.223 56.76079,-1.024 85.13721,-1.33 24.17379,-0.261 48.42731,0.571 72.58115,0.571"></path>
            <path id="svg_line_2" d="m 1.986,33.91 c 55.429038,4.081 111.58111,5.822 167.11781,2.867 22.70911,-1.208 45.39828,-0.601 68.126,-0.778 28.38173,-0.223 56.76079,-1.024 85.13721,-1.33 24.17379,-0.261 48.42731,0.571 72.58115,0.571"></path>
          </svg>
        </span>
      </h1>
    </div>
  );
};

export default HeroSection;
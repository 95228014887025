import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import styles from './contactSection.module.css';

const ContactSection = () => {

  const contactLinksTab = [
    { text: "louisemendiburu@gmail.com", icon: "/icon/icon-email.svg" },
    { text: "06 45 23 82 50", icon: "/icon/icon-phone.svg" }
  ];
  
  const socialLinksTab = [
    {
      text: "Louise Mendiburu", 
      icon: "/icon/icon-linkedin.svg", 
      link: "https://www.linkedin.com/in/louise-mendiburu/"
    },
    {
      text: "@into_louiseworld", 
      icon: "/icon/icon-instagram.svg", 
      link: "https://www.instagram.com/into_louiseworld/"
    }
  ];

  const contactLinks = contactLinksTab.map(element => {
    return (
      <li key={element.text} className={styles.linksListElement}>
        <img src={element.icon} alt={element.text} className={styles.icon} />
        <p>{element.text}</p>
      </li>
    );
  });

  const socialLinks = socialLinksTab.map(element => {
    return (
      <li key={element.text} className={styles.linksListElement}>
        <img src={element.icon} alt={element.text} className={styles.icon} />
        <a href={element.link} className={styles.link} target='_blank' rel="noreferrer">
          <p>
            {element.text}
            <svg className={styles.linksvgline} viewBox="0 0 400 50" xmlns="http://www.w3.org/2000/svg">
              <path id="svg_line" d="m 1.986,8.91 c 55.429038,4.081 111.58111,5.822 167.11781,2.867 22.70911,-1.208 45.39828,-0.601 68.126,-0.778 28.38173,-0.223 56.76079,-1.024 85.13721,-1.33 24.17379,-0.261 48.42731,0.571 72.58115,0.571"></path>
            </svg>
          </p>
        </a>
      </li>
    );
  });

  return (
    <Fragment>
      <div id="contact" className={styles.root}>
        <h2 className={styles.title}>N'hésitez pas à me contacter&nbsp;!</h2>
        <ul className={styles.linksList}>
          {contactLinks}
        </ul>
        <ul className={styles.linksList}>
          {socialLinks}
        </ul>
      </div>
      <div className={styles.footer}>
        <img src="/images/logo.png" className={styles.footerLogo} alt="logo"/>
        <Link className={styles.legalNotice} to="/mentions-legales"> Mentions légales </Link>
        <p className={styles.footerText}>© 2024 - Louise Mendiburu</p>
      </div>
    </Fragment>
  );
};

export default ContactSection;
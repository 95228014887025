import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import ContactSection from '../ContactSection';
import styles from './notFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.container}>
        <h1>Oups! Cette page n'existe pas.</h1>
        <img src="/images/not-found.jpg" className={styles.picture} alt="not found"/>
        <Link className={styles.backButton} to="/">
          <p>Retour à l'accueil</p>
        </Link>
      </div>
      <div className={styles.footer}>
        <img src="/images/logo.png" className={styles.footerLogo} alt="logo"/>
        <Link className={styles.legalNotice} to="/mentions-legales"> Mentions légales </Link>
        <p className={styles.footerText}>© 2024 - Louise Mendiburu</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
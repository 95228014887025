import React from 'react';

import styles from './header.module.css';

const Header = props => {
    return (
      <div className={styles.root}>
        <img src="/images/logo.png" alt="Logo" />
      </div>
    );
};

export default Header;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../Loader';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './projectSection.module.css';

const ProjectSection = () => {
  const [allData, setAllData] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const comparerIds = (a, b) => {
    let idA = atob(a.id);
    let idB = atob(b.id);

    if (idA > idB) { return -1; }
    if (idA < idB) { return 1; }
    return 0;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.louise-mendiburu.fr/api-portfolio/project/list');
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const jsonData = await response.json();
        setData(jsonData.data.sort(comparerIds).slice(0, 3));
        setAllData(jsonData.data.sort(comparerIds));
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []); 

  if (loading) {
    return (
      <div id="projects" className={styles.rootLoading}>
        <h2>Découvrez mes projets</h2>
        <div className={styles.loadingContainer}>
          <Loader />
          <p>Chargement en cours...</p>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div id="projects" className={styles.root}>
        <h2>Découvrez mes projets</h2>
        <p>Aucun projet disponible.</p>
      </div>
    );
  }

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 669,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const allProjects = allData.map(element => {
    const urlProject = `/project/${element.id}`;
    return (
      <Link key={element.id} className={styles.projectContainer} to={urlProject}>
        <div className={styles.thumbnailContainer}>
          <img src={element.thumbnail} className={styles.thumbnail} alt={element.title}/>
        </div>
        <h3>{element.title}</h3>
        <p>{element.domain}</p>
        <p>{element.date}</p>
      </Link>
    );
  });

  return (
    <div id="projects" className={styles.root}>
      <h2>Découvrez mes projets</h2>

      <div className={styles.projectsList}>
        <Slider {...settings} className={styles.slider}>{allProjects}</Slider>
      </div>
    </div>
  );
};

export default ProjectSection;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Homepage from '../Homepage';
import ProjectPage from '../ProjectPage';
import LegalNoticePage from '../LegalNoticePage';
import NotFoundPage from '../NotFoundPage';

const App = () => { 
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/project/:id" element={<ProjectPage />} />
        <Route exact path="/mentions-legales" element={<LegalNoticePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
import React, { useState, useEffect } from 'react';

import Header from '../Header';
import DesktopMenu from '../DesktopMenu';
import MobileMenu from '../MobileMenu';
import HeroSection from '../HeroSection'
import AboutMeSection from '../AboutMeSection';
import ProjectSection from '../ProjectSection';
import ContactSection from '../ContactSection';
import styles from './homepage.module.css';

const Homepage = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 992) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowWidth]);

  return (
    <div className={styles.root}>
      <Header />
      {isDesktop ? (
        <DesktopMenu />
      ) : (
        <MobileMenu />
      )}
      <HeroSection />
      <AboutMeSection />
      <ProjectSection />
      <ContactSection />
    </div>
  );
};

export default Homepage;
import React, { useState, useEffect, Fragment } from 'react';

import styles from './mobileMenu.module.css';

const MobileMenu = () => {

  const linksTab = [
    { name: "Accueil", link: "home" },
    { name: "Qui je suis", link: "about-me" },
    { name: "Mes projets", link: "projects" },
    { name: "Contact", link: "contact" },
  ];

  var linkElementClass ;
  const [currentLink, setCurrentLink] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [iconClass, setIconClass] = useState(styles.icon);
  const [rootClass, setRootClass] = useState(`${styles.root} ${styles.rootClosed}`);

  const handleLink = (link) => {
    setIsMenuOpen(false);
    setCurrentLink(link);
    const section = document.getElementById(link);
    section.scrollIntoView({block: "start", behavior: "smooth"});
  };

  const handleToggleMenu = () => {
    setIsMenuOpen(value => !value);
  };

  useEffect(() => {
    if (isMenuOpen) {
      setIconClass(`${styles.icon} ${styles.cross}`);
      setRootClass(styles.root);
    } else {
      setIconClass(styles.icon);
      setRootClass(`${styles.root} ${styles.rootClosed}`);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentLink(entry.target.id);
        }
      });
    }, options);

    ['home', 'about-me', 'projects', 'contact'].forEach((id) => {
      const target = document.getElementById(id);
      if (target) {
        observer.observe(target);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const links = linksTab.map(element => {
    if (currentLink === element.link) {
      linkElementClass = styles.linksListElementActive;
    } else {
      linkElementClass = styles.linksListElement;
    }
    
    return (
      <li key={element.name} className={linkElementClass}>
        <button
          className={styles.link}
          onClick={() => handleLink(element.link)}
        >
          <span className={styles.text}>{element.name}</span>
        </button>
      </li>
    );
  });

  return (
    <Fragment>
      <svg className={iconClass} viewBox="0 0 800 600" onClick={handleToggleMenu}>
          <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" className={styles.pathTop}></path>
          <path d="M300,320 L540,320" id="middle" className={styles.pathMiddle}></path>
          <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" className={styles.pathBottom} transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
      </svg>
      <div className={rootClass}>
        <ul className={styles.linksList}>
          {links}
        </ul>
      </div>
      {isMenuOpen ? <div className={styles.mask} onClick={() => setIsMenuOpen(false)}></div> : null}
    </Fragment>
  );
};

export default MobileMenu;
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import ContactSection from '../ContactSection';
import styles from './legalNoticePage.module.css';

const LegalNoticePage = () => {
    return (
        <Fragment>
            <Header />
            <div className={styles.root}>
                <header className={styles.header}>
                    <Link className={styles.backButton} to="/">
                        <img src="/icon/icon-arrow-left.svg" alt="Retour"/>
                        <p>Retour à l'accueil</p>
                    </Link>
                    <h1>Mentions légales</h1>
                    <p>
                        Les présentes conditions générales fixent les modalités d'utilisation du service web <a href="https://louise-mendiburu.fr" className={styles.link}>louise-mendiburu.fr</a> <br/>
                        L'utilisateur reconnaît avoir pris connaissance de ce document et accepté l'ensemble de ces informations, que cet usage soit fait à titre personnel ou professionnel.
                        Si cet usage est fait à titre professionnel, l'utilisateur garantit détenir les pouvoirs nécessaires pour accepter ces conditions générales au sein de son organisation.
                    </p>
                </header>

                <h2>Editeur et responsable de publication</h2>
                <p>Ce site portofolio non déclaré à la CNIL est édité par Louise Mendiburu.</p>

                <h2>Hébergement</h2>
                <p>Ce site est hébergé par Infomaniak Network SA.</p>
                <p>
                    Adresse : Avenue de la Praille 26, 1227 Carouge / Genève, Suisse <br/>
                    Site web : <a href="https://www.infomaniak.com" className={styles.link} target='_blank' rel="noreferrer">www.infomaniak.com</a>
                </p>
                <p>Cet hébergeur détient à ce jour les informations personnelles concernant l'auteur de ce site.</p>

                <h2>Responsabilité</h2>
                <p>
                    Toutes les informations indiquées sur le site sont données à titre indicatif, et sont susceptibles d'évoluer. <br/>
                    Par ailleurs, les renseignements figurant sur le site <a href="https://louise-mendiburu.fr" className={styles.link}>louise-mendiburu.fr</a> ne sont pas exhaustifs.
                    Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                    Il advient à l'utilisateur de s'assurer de la pertinence de ces informations au regard de sa situation. <br/>
                    L'utilisateur s'engage donc à utiliser ces informations sous son entière responsabilité et dégage l'éditeur de toute responsabilité à cet égard.
                </p>

                <h2>Limitations contractuelles sur les données techniques</h2>
                <p>
                    Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu responsable des dommages matériels liés à l'utilisation du site. <br/>
                    De plus, l'utilisateur du site s'engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
                </p>

                <h2>Propriété intellectuelle</h2>
                <p>
                    Louise Mendiburu est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site, notamment les textes, images, logo (sauf mentions contraires). <br/>
                    Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite au préalable.
                    Toute exploitation non autorisée du site ou de l'un des quelconque éléments qu'il contient sera considéré comme constitutif d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants sur Code de Propriété Intellectuelle. <br/>
                    Cela dit, conformément à l'article L.122-5 du Code de la propriété intellectuelle, les courtes citations du contenu sont autorisées, sous réserve que soient indiqués clairement le nom de l'auteur et de la source, par un lien vers une des pages de ce site web.
                </p>

                <h2>Cookies</h2>
                <p>
                    L'utilisateur est informé que des cookies peuvent s'installer sur son ordinateur.
                    Dans tous les cas, l'utilisateur peut librement effaces ces cookies de son navigateur.
                </p>

                <h2>Traitement des données personnelles</h2>
                <p>Le site n'est pas déclaré à la CNIL car il ne recueille pas d'informations personnelles.</p>
            </div>
            <div className={styles.footer}>
                <ContactSection />
            </div>
        </Fragment>
    );
};

export default LegalNoticePage;
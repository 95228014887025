import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import Header from '../Header';
import Loader from '../Loader';
import ContactSection from '../ContactSection';
import styles from './projectPage.module.css';

const ProjectPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.louise-mendiburu.fr/api-portfolio/project/list?id='+id);
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const jsonData = await response.json();
        if (!project) {
          setProject(jsonData.data[0]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        setLoading(false);
      }
    };
    fetchData();

    if (!loading && !project) {
      navigate('/mentions-legales');
    }
  }, [id, navigate, project, loading]);

  if (!project) {
    return (
      <Fragment>
        <Header />
        <div className={styles.rootLoading}>
          <Loader />
          <p>Chargement du projet...</p>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header />
      <div className={styles.root}>
        <Link className={styles.backButton} to="/">
          <img src="/icon/icon-arrow-left.svg" alt="Retour"/>
          <p>Retour à l'accueil</p>
        </Link>
        <div className={styles.header}>
          <h1>{project.title}</h1>
          <p>{project.domain} - {project.date}</p>
        </div>
        <div className={styles.projectContainer}>
          <div className={styles.pictureContainer}>
            {project.link_photo ? (
              <img src={project.link_photo} className={styles.picture} alt={project.title} />
            ) : (
              <img src={project.thumbnail} className={styles.picture} alt={project.title} />
            )}
            
          </div>
          <div>
            <p className={styles.description}>{project.description}</p>
            {project.link_web ? (<a href={project.link_web} className={styles.linkWeb} target="_blank" rel="noreferrer">Voir le projet web</a>) : null}
            {project.link_pdf ? (<a href={project.link_pdf} className={styles.linkWeb} target="_blank" rel="noreferrer">Voir le projet graphique</a>) : null}
          </div>  
        </div>
        {project.link_video ? (
          <div className={styles.projectVideo}>
            <iframe className={styles.linkVideo} src={project.link_video} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        ) : null}
        <div className={styles.footer}>
          <ContactSection />
        </div>
      </div>
    </Fragment>
  );
};

export default ProjectPage;
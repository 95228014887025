import React, { useState, useEffect } from 'react';

import styles from './desktopMenu.module.css';

const DesktopMenu = () => {

  const linksTab = [
    { name: "Accueil", link: "home" },
    { name: "Qui je suis", link: "about-me" },
    { name: "Mes projets", link: "projects" },
    { name: "Contact", link: "contact" },
  ];

  const [currentLink, setCurrentLink] = useState("home");
  var dotClass ;

  const handleLink = (link) => {
    setCurrentLink(link);
    const section = document.getElementById(link);
    section.scrollIntoView({block: "start", behavior: "smooth"});
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentLink(entry.target.id);
        }
      });
    }, options);

    ['home', 'about-me', 'projects', 'contact'].forEach((id) => {
      const target = document.getElementById(id);
      if (target) {
        observer.observe(target);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const links = linksTab.map(element => {
    if (currentLink === element.link) {
      dotClass = styles.dotActive;
    } else {
      dotClass = styles.dot;
    }
    
    return (
      <li key={element.name} className={styles.linksListElement}>
        <button
          className={styles.link}
          onClick={() => handleLink(element.link)}
        >
          <span className={styles.text}>{element.name}</span>
          <span className={dotClass}></span>
        </button>
      </li>
    );
  });

  return (
    <div className={styles.root}>
      <nav className={styles.nav}>
        <ul className={styles.linksList}>
          {links}
        </ul>
      </nav>
    </div>
  );
};

export default DesktopMenu;
import React, { Fragment } from 'react';

import styles from './aboutMeSection.module.css';

const AboutMeSection = () => {

  const langageTab = [
    { title: "Langages web", content: "HTML, CSS, JavaScript, PHP", icon: "/icon/icon-code.svg" },
    { title: "Langages de requête", content: "SQL, GraphQL", icon: "/icon/icon-db.svg" },
    { title: "Frameworks", content: "React, Vue.js, Laravel", icon: "/icon/icon-download.svg" },
    { title: "CMS", content: "Magento 2, Wordpress", icon: "/icon/icon-download.svg" }
  ];

  const langageList = langageTab.map(element => {
    return (
      <li key={element.title} className={styles.linksListElement}>
        <img src={element.icon} alt={element.title} className={styles.icon} />
        <p className={styles.listTitle}>{element.title}</p>
        <p className={styles.content}>{element.content}</p>
      </li>
    );
  });

  return (
    <div id="about-me" className={styles.root}>
      <div className={styles.descriptionContainer}>
        <div className={styles.descriptionPicture}>
          <img src="/images/louise.png" className={styles.picture} alt="louise" />
        </div>
        <div className={styles.descriptionContainerText}>
          <h2>Bonjour ! Moi c'est Louise.</h2>
          <p>Je suis étudiante en alternance en BUT Métiers du Multimédia et de l'Internet (MMI) à Grenoble, plongée dans une formation où se mélangent à la fois le développement web, le design graphique, l'audiovisuel et la communication.</p>
          <p> 
            En ce moment, je travaille chez Evolutis en tant que développeuse web. 
            Là-bas, je crée des Progressive Web Apps (PWA) en React JS et je découvre les rouages du CMS Magento 2. 
            C'est l'occasion parfaite pour mettre mes compétences en pratique et pour en apprendre toujours plus sur le développement web. 
          </p>
          <p>En parallèle, je suis à la recherche d'une entreprise pour la rentrée prochaine afin d'y effectuer mon master en alternance.</p>
          <div className={styles.pdfLink}>
            <img src="/icon/icon-download.svg" alt="download cv"/>
            <a href="/pdf/pdf-cv.pdf" target="_blank">
              Téléchargez mon CV
              <svg className={styles.linksvgline} viewBox="0 0 400 50" xmlns="http://www.w3.org/2000/svg">
                <path id="svg_line" d="m 1.986,8.91 c 55.429038,4.081 111.58111,5.822 167.11781,2.867 22.70911,-1.208 45.39828,-0.601 68.126,-0.778 28.38173,-0.223 56.76079,-1.024 85.13721,-1.33 24.17379,-0.261 48.42731,0.571 72.58115,0.571"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.langageContainer}>
        <h3>Les technologies que j'utilise</h3>
        <ul className={styles.linksList}>{langageList}</ul>
      </div>
    </div>
  );
};

export default AboutMeSection;